<template>
  <v-container
    fluid
    grid-list-md
  >
    <v-layout
      justify-space-around
      row
      wrap
      fill-height
    >
      <v-flex
        v-for="(item, i) in value"
        :key="i"
        xs12
        sm4
      >
        <v-card
          class="mt-4 mx-auto"
          max-width="350px"
        >
          <v-layout
            align-center
            justify-space-between
            row
          >
            <v-flex xs3>
              <v-sheet
                class="v-sheet--offset ml-3 py-1 pl-1"
                :color="color(i)"
                elevation="12"
                height="65px"
                width="65px"
              >
                <v-icon
                  dark
                  x-large
                >
                  {{ item.icon }}
                </v-icon>
              </v-sheet>
            </v-flex>
            <v-flex
              xs7
              offset-xs2
            >
              <div class="title font-weight-light">
                {{ item.value }}
              </div>
            </v-flex>
          </v-layout>
          <v-card-text class="pt-0">
            <v-btn
              v-if="item.kpi"
              absolute
              dark
              fab
              top
              right
              :ripple="false"
              class="transparent"
            >
              <v-avatar
                size="50px"
                :color="kpiColor(item.kpi)"
              >
                <span
                  v-if="(typeof item.kpi === 'number' && item.kpi > 0) || item.kpi !== '0'"
                  class="white--text subheader"
                >
                  KPI 0{{ item.kpi }}
                </span>
                <span
                  v-else
                  class="black--text subheader"
                  v-html="`KPI<br>VOID`"
                />
              </v-avatar>
            </v-btn>
            <div>
              <span class="subheading font-weight-light grey--text">{{ item.name }}</span>
              <span
                v-if="item.nameValue"
                class="subheading font-weight-medium"
                v-html="item.nameValue"
              />
            </div>
            <template v-if="item.remark">
              <v-divider class="my-2" />
              <v-icon
                class="mr-2"
                small
              >
                {{ item.remarkIcon ? item.remarkIcon : 'mdi-information' }}
              </v-icon>
              <span class="caption grey--text font-weight-light">{{ item.remark }}</span>
            </template>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-divider class="mt-3" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
  },
  data () {
    return {
      colors: [
        'red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green',
        'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey',
      ],
      colorIndex: 0,
    }
  },
  created: function () {
    let currentIndex = this.colors.length
    let color
    let randomIndex

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex--)
      color = this.colors[currentIndex]
      this.colors[currentIndex] = this.colors[randomIndex]
      this.colors[randomIndex] = color
    }
  },
  methods: {
    kpiColor: function (kpi) {
      if (typeof kpi === 'number') {
        switch (kpi * 1) {
          case 3: return '#800000'
          case 2: return 'orange darken-3'
          case 1: return 'grey'
          default: return 'white'
        }
      } else {
        if (kpi === '1A') {
          return 'grey'
        } else if (kpi === '1B') {
          return 'grey darken-2'
        } else if (kpi === '1C') {
          return 'grey darken-3'
        } else if (kpi === '2') {
          return 'orange darken-3'
        } else if (kpi === '3') {
          return '#800000'
        } else {
          return 'white'
        }
      }
    },
    color (index) {
      return this.colors[index] + ' darken-2'
    },
  },
}
</script>
